const Navigation = (function () {
  let navigationButton = undefined;
  let navigationCloseButton = undefined;
  let navigationMobileScreen = undefined;

  const enableEventListeners = () => {
    navigationButton.addEventListener("click", () => {
      navigationMobileScreen.classList.remove("hidden");
    });

    navigationCloseButton.addEventListener("click", () => {
      navigationMobileScreen.classList.add("hidden");
    });
  };

  const setup = (pNavigationButton, pNavigationCloseButton, pNavigationMobileScreen) => {
    navigationButton = pNavigationButton;
    navigationCloseButton = pNavigationCloseButton;
    navigationMobileScreen = pNavigationMobileScreen;

    enableEventListeners();
  };

  return {
    setup: setup,
  };
})();

export default Navigation;
