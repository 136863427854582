import Navigation from "./lib/navigation";

(() => {
  document.addEventListener("DOMContentLoaded", () => {
    const navigationButton = document.querySelector(".js-hamburger");
    const navigationCloseButton = document.querySelector(".js-close");
    const navigationMobileScreen = document.querySelector(".js-navigation-mobile");

    if (navigationButton != null && navigationCloseButton != null && navigationMobileScreen != null) {
      Navigation.setup(navigationButton, navigationCloseButton, navigationMobileScreen);
    }
  });
})();
